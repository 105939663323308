
function createMarkup(wording) {
  return {__html: wording};
}


function TutorialItem(props) {

  const { step, wording, img} = props;

  return (
    <div className="tutorial">
      <div>
        <div className="tutorialAroundContent">
          <div className="tutorialStepNumber">{step})</div>
            <div className="tutorialStepContent" dangerouslySetInnerHTML={createMarkup(wording)}></div>
        </div>
        <div>
          <img className='tutorial' src={img} alt="Tutorial step"/>
        </div>
      </div>
    </div>
  );
}

export default TutorialItem;
