import React, {useEffect } from 'react'
import { Routes, Route } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga4';

import Home from '../components/Home'
import Contact from '../components/Contact'
import NoMatch from '../components/NoMatch'
import Sidebar from '../components/layout/SideBar';
import ArcadeInput from '../components/ArcadeInput';
import Leaderboard from '../components/Leaderboard';
import SimpleEventSystem from '../components/SimpleEventSystem';
import CosmosProtector from '../components/CosmosProtector';
import Shifters  from '../components/Shifters';
import OneOnTheRun from '../components/OneOnTheRun';
import HoldTheLine from '../components/HoldTheLine';
import Privacy from '../components/PrivacyPolicy';

ReactGA.initialize("G-70ZYENY570");

const Routing = () => {
  ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search});
  return (
    <HashRouter>
      <Helmet>
        <title>Quiet Village Games Website</title>
        <meta
          name="description"
          content="Quiet Village Games game development studio website"
        />
        <meta
          name="keywords"
          content="Games, Quiet, Village"
        />
      </Helmet>
      <div>
        <div>
          <div>
            <a href='/'>
              <img className='logo' src={require("../assets/images/logo.png")} alt="logo"/>
            </a>
          </div>
        </div>
      </div>
      <div>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{height: '100%' }} className="">
            <Sidebar />
          </div>
          <div className='content'>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/arcadeTextInput" element={<ArcadeInput />} />
              <Route path="/simpleEventSystem" element={<SimpleEventSystem />} />
              <Route path="/leaderboard" element={<Leaderboard />} />
              <Route path="/cosmosProtector" element={<CosmosProtector />} />
              <Route path="/holdTheLine" element={<HoldTheLine />} />
              <Route path="/oneOnTheRun" element={<OneOnTheRun />} />
              <Route path="/shifters" element={<Shifters />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/privacyPolicy" element={<Privacy />} />
              <Route component={NoMatch} />
            </Routes>
          </div>
        </div>
      </div>
    </HashRouter>
  )
}

export default Routing
