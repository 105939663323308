
function PrivacyPolicy() {
  return (
    <div>
      <header>
        <h2>Privacy Policy</h2>
      </header>
      <div>
        <p>Effective Date: Nov 19th, 2022</p>
        <p>
Quiet Village Games ("we," "us," or "our") is committed to protecting the privacy and security of your personal information. This Privacy Policy describes how we collect, use, disclose, and protect the personal information we obtain from users of our website, games, applications, and services (collectively, the "Services"). By accessing or using our Services, you agree to this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use our Services.
</p>
<h3>1. Information We Collect</h3>
<p>
We may collect personal information from you when you use our Services. This information may include:

<ul><li>Personal information you provide to us, such as your name, email address, and other contact information.</li>
<li>Information automatically collected when you use our Services, such as your IP address, device information, and usage data.</li>
<li>Information collected through cookies and similar tracking technologies.</li>
</ul></p>

<h3>2. How We Use Your Information</h3>
<p>
We may use the personal information we collect for various purposes, including to:

<ul>
<li>Provide and improve our Services.</li>
<li>Communicate with you about our Services.</li>
<li>Customize your experience with our Services.</li>
<li>Analyze usage of our Services and improve their performance.</li>
<li>Comply with legal obligations.</li>
</ul></p>

<h3>3. How We Share Your Information</h3>
<p>
We may share your personal information with third parties for various purposes, including:
<ul>
<li>Service providers: We may share your information with third-party service providers who assist us in providing and improving our Services.</li>
<li>Legal purposes: We may disclose your information in response to a subpoena, court order, or other legal request.</li>
<li>Business transfers: If we are involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.</li>
<li>Consent: We may share your information with your consent or as otherwise required by law.</li>
</ul></p>

<h3>4. Your Choices</h3>
<p>
You have certain choices regarding the collection, use, and sharing of your personal information. You may:

<ul>
<li>Opt out of certain data collection and sharing practices.</li>
<li>Update or correct your personal information.</li>
<li>Request that we delete your personal information, subject to certain exceptions.</li>
</ul></p>

<h3>5. Security</h3>
<p>
We take reasonable measures to protect the security of your personal information. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee the absolute security of your information.
</p>

<h3>6. Children's Privacy</h3>
<p>
Our Services are not intended for children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us so that we can delete the information.
</p>

<he>7. Changes to this Privacy Policy</he>
<p>
We may update this Privacy Policy from time to time by posting a new version on our website or through other appropriate means. Your continued use of our Services after any such changes constitutes your acceptance of the updated Privacy Policy.
</p>

<h3>8. Contact Us</h3>
<p>
If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at quietvillagegameshelp@gmail.com.
</p><p>
Thank you for reading our Privacy Policy.
</p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
