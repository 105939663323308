function createMarkup(wording) {
  return {__html: wording};
}


function NewsItem(props) {

  const { date, wording, img} = props;

  return (
    <div className="tutorial">
      <div>
          <div className="newsItemAroundContent">
            <div className='date'>
              {date}
            </div>
            <div className="tutorialStepContent" dangerouslySetInnerHTML={createMarkup(wording)}></div>
          </div>
      </div>
    </div>
  );
}

export default NewsItem;
