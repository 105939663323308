
import { Helmet } from "react-helmet";
import YouTube from 'react-youtube';

function OneOnTheRun() {
  const youtubeOpts = {
      height: '390',
      width: '640',
      playerVars: {
        autoplay: 0,
      }
  };
  const transitionTime = 200;
  return (
    <div>
      <Helmet>
        <title>One On The Run - Dodge, Slide, Kick: Outrun foes, collect orbs, and dominate the chase! How long can you stay uncatchable?</title>
        <meta
            name="description"
            content="Dodge, Slide, Kick: Outrun foes, collect orbs, and dominate the chase! How long can you stay uncatchable?"
        />
        <meta
          name="keywords"
          content="One On The Run, adventure, endless-runner, infinite-chase, official website, Games, Quiet, Village"
        />
      </Helmet>
      <header>
        <h2>One On The Run</h2>
      </header>
      <div>
        <p>
          Embark on an adrenaline-fueled escapade in "One On The Run," an exhilarating infinite chase game that pushes your reflexes and strategic skills to the limit! Plunge into a surreal world where danger lurks at every turn, and your only objective is to outrun the relentless pursuit.
        </p><p>
        Dodge treacherous rock formations, sidestep slithering snakes, and navigate around ominous pools of boiling blood as you sprint through dynamically shifting landscapes. But it's not just survival – it's about mastering the art of evasion while simultaneously sliding and kicking statues to amass a fortune in glittering orbs.
        </p><p>
        Your journey is a high-stakes race against time and relentless foes, with every move requiring split-second decision-making. Harness your agility to outwit the relentless pursuit, kick statues strategically to gather orbs, and defy the odds to become the ultimate escape artist.
        </p><p>
        Challenge your friends to a pulse-pounding showdown as you vie for supremacy on the global leaderboard. Rise through the ranks, leaving a trail of defeated competitors in your wake. Will you be the most uncatchable player in this heart-pounding chase?
        </p><p>
        With each session presenting new challenges and opportunities, "One On The Run" promises an endless pursuit where the only limit is your skill and determination. Get ready to run, slide, kick, and dominate in this relentless and addictive chase for glory!
        </p>
      < hr />
      <div className="youtube">
        <YouTube videoId="PbBCfAvxfxs" opts={youtubeOpts} />
      </div>
      < hr />
      <div>
        <p align="center">
          <a href="https://play.google.com/store/apps/details?id=com.QuietVillageGames.OneOnTheRun">Google Play Store</a>
        </p>
        <p align="center">
          <iframe frameborder="0" title="One On The Run" src="https://itch.io/embed/2419900?linkback=true&amp;dark=true" width="552" height="167"><a href="https://xxchester.itch.io/one-on-the-run">One On The Run by XXChester</a></iframe>
        </p>
      </div>
      </div>
    </div>
  );
}

export default OneOnTheRun;
