
import { Helmet } from "react-helmet";
import { HashLink as Link } from 'react-router-hash-link';
import YouTube from 'react-youtube';
import Collapsible from 'react-collapsible';


function SimpleEventSystem() {
  const youtubeOpts = {
      height: '390',
      width: '640',
      playerVars: {
        autoplay: 0,
      }
  };
  const transitionTime = 200;
  return (
    <div>
      <Helmet>
        <title>Unity Game Event System: Implementation Guide</title>
        <meta
            name="description"
            content="Ready to start using a Unity Game event system in your game development process? This implementation guide has everything you need to get started, from setting up the system to creating and using custom events."
        />
        <meta
          name="keywords"
          content="How to implement a Unity Game event system, Simple, Event, System, SES, Unity, Games, Quiet, Village"
        />
      </Helmet>
      <header>
        <h2>Simple Event System (SES)</h2>
      </header>
      <div>
      <p>Having utilized the event system for an extended duration, I encountered recurring challenges. Primarily, there existed no means to trigger Typed Game Events, as the custom editor was limited to no-argument Game Events. Furthermore, a recurring issue emerged when configuring numerous listeners for events such as Boolean Game Events. Inevitably, situations arose where I needed to incorporate additional parameters like an associated ID for the event raiser. Regrettably, the absence of an option to modify the underlying script of existing listeners led to the loss of their mappings.
      </p><p>
        Consequently, this gave rise to the conception of Simple Event System, or SES. SES serves as an exceptionally user-friendly event system encompassing Producers and Listeners, bespoke Editors for prompt event activation from the Inspector, and effortless expandability. A distinctive feature of SES is its tailored Game Event References Editor, revealing all linked Producers and Listeners pertinent to a specific event.
      </p><p>
        In summation, the motivation behind SES stemmed from the need to address these challenges comprehensively. It was developed to tackle the intricacies of event handling, ultimately delivering a versatile solution that caters to seamless event management and enhanced productivity. SES is available on the Unity Asset Store <a href="https://assetstore.unity.com/packages/slug/247545">here</a></p>
      </div>
      <div className="youtube">
        <YouTube videoId="s0Z7mFnR0FM" opts={youtubeOpts} />
        <YouTube videoId="bOeN9wf8wqo" opts={youtubeOpts} />
        <YouTube videoId="UnY7pvd-Glw" opts={youtubeOpts} />
      </div>
      <hr />
      <div className="tutorial">
        <div className="">
          <Collapsible trigger={<h3 id="gettingStarted">Getting Started With Events</h3>} transitionTime={transitionTime}>
            <div>
              <p>Event Systems are a critical piece of Game Development. They allow you to isolate and decouple components from hard dependencies on each other which quickly becomes a mess on any real project. Alternatively, you use what are called Producers and Listeners or the Observer Pattern. From one part of your game, you would produce an event and in other systems of the game you would have listeners waiting to invoke actions once that event is received.</p>
              <p>For instance, if you had a door open trigger, your script that does the detection would raise an Open Door Game Event. You could then have a door system and a sound effects system listening for the Open Door Game Event. Once the event is received, the Door System would trigger the door open animation and the Sound Effect System would play the open door sound effect. This would keep your 3 systems (Game Play, Door Management, and Sound Effects) completely self-contained and unaware of the other systems.</p>
            </div>
          </Collapsible>
        </div>
      </div>
      <div className="tutorial">
        <div className="">
          <Collapsible trigger={<h3 id="gettingStarted">SES With Unity</h3>} transitionTime={transitionTime}>
              <p>SES is extremely easy to get started with, simply create your Game Events which are Scriptable Objects via the right click Context Menu &rarr; Scriptable Objects &rarr; Game Events and select a predefined Game Event or a custom one you have setup. Later we will show you how to create your own Game Events (it’s really easy).</p>
              <div>
                <img className='tutorial' src={require("../assets/images/SES/context-menu.png")} alt="Context menu"/>
              </div>
              <hr className="tutorial" />
            <div className="tutorialStepContent">
              <h4>Creating the Listener</h4>
              <p>After you have created your Game Event it is time to wire it into Unity via dragging and dropping. When you drag the event onto a Game Object in the Hierarchy or onto the Inspector, a Game Event Listener with the proper types will automatically be created for you.</p>
              <div>
                <img className='tutorial' src={require("../assets/images/SES/listener-added.png")} alt="Listener added"/>
              </div>
              <p>The last step for the Listener is to setup actions to take once the Game Event is produced. In the simple example below, we have set up a response that turns a Game Objects active state on or off depending on whether the dynamic boolean sent by the producer is true or false.</p>
              <div>
                <img className='tutorial' src={require("../assets/images/SES/dynamic-bool.png")} alt="Dynamic bool option"/>
              </div>
              <div>
                <img className='tutorial' src={require("../assets/images/SES/listener-configure.png")} alt="Listener configured"/>
              </div>
              <p>That is it; you are now ready to start triggering this event. To quickly see it in action, hit Play, click your Boolean Game Event, change the boolean value and hit Raise and observe your object showing and hiding within the scene based on the true or false value sent.</p>
              <div>
                <img className='tutorial' src={require("../assets/images/SES/raising-event.png")} alt="raising an event"/>
              </div>
              <hr className="tutorial" />
              <div className="tutorialStepContent">
              <h4>Creating the Producer</h4>
                <p>Producers are responsible for broadcasting the Game Event of your choice when a certain action takes place such as the player losing health, or dying. Go ahead and create a new C# Script. Add in a Game Event field such as below:</p>
                <div>
                  <img className='tutorial' src={require("../assets/images/SES/dummy-script.png")} alt="dummy script"/>
                </div>
                <p>In this example, every time the counter reaches 500, we are producing a Float Game Event passing 1.0f as the value. Also, when the counter reaches 1000, we produce a generic Game Event.</p>
                <div>
                  <img className='tutorial' src={require("../assets/images/SES/dummy-component.png")} alt="dummy component example"/>
                </div>
              </div>
            </div>
          </Collapsible>
        </div>
      </div>
      <div className="tutorial">
        <div className="">
          <Collapsible trigger={<h3 id="gettingStarted">Game Event References Window</h3>} transitionTime={transitionTime}>
            <p>SES Comes with a custom editor accessed via the Tools &rarr; Game Event References menu item. While the window is open, select one of your Game Events created with SES. If you have not added any producers or listeners for the Game Event, only the Game Event name will be filled in. The power comes in once you add Listeners and Producers. This editor will show you all references in the scene of each event, which gives a very quick glance at all of the places a Game Event is used.</p>
                <div>
                  <img className='tutorial' src={require("../assets/images/SES/game-event-references.png")} alt="game event references editor"/>
                </div>
                <p>Clicking any of the Producer or Listener nodes will highlight the object in the Hierarchy so it can quickly be identified.</p>
          </Collapsible>
        </div>
      </div>
      <div className="tutorial">
        <div className="">
          <Collapsible trigger={<h3 id="gettingStarted">Custom Game Events</h3>} transitionTime={transitionTime}>
              <div className="tutorialStepContent">
              <p>Creating your own data types to use as Game Events is extremely easy in SES. With a few classes containing little code or sometimes even none, you can have a custom Game Event running in no time. At a minimum, you have to create your new Game Event and also create the Game Event Listener so you can add it to components.</p>
            </div>
            <hr className="tutorial" />
            <div className="tutorialStepContent">
              <h4>Creating the Listener</h4>
              <p>Creating a new Listener can be done with no custom code, the only thing that needs to be changed is the DataType passed into the TypedGameEventListener&lt;&#123;DATA TYPE&#125;&gt;. This can be any serialized DataType including your own data structures.</p>
              <div>
                <img className='tutorial' src={require("../assets/images/SES/bool-game-event-listener.png")} alt="Bool game event listener"/>
              </div>
            </div>
            <hr className="tutorial" />
            <div className="tutorialStepContent">
              <h4>Creating the Game Event</h4>
              <p>Creating the Game Event is very little code as well, the only real changes you need to make are the DataType passed into TypedGameEvent&lt;&#123;DATA TYPE&#125;&gt; and the Type of your new Listener. See below.</p>
              <div>
                <img className='tutorial' src={require("../assets/images/SES/bool-game-event.png")} alt="bool game event"/>
              </div>
              <p>That is it, you now have a brand new event type that can be listened for and produced.</p>
            </div>
          </Collapsible>
        </div>
      </div>
      <div className="tutorial">
        <div className="">
          <Collapsible trigger={<h3 id="gettingStarted">Advanced – Custom Editors</h3>} transitionTime={transitionTime}>
            <div className="tutorialStepContent">
              <p>We recommend you take it one step further though with a custom Editor Script for your component which enables you to change the backing script without losing references, and filter the Game Event finder to only applicable data types.</p>
              <p>In order to access all of this additional functionality, create an Editor folder and create a new Script in there suffixed with Editor (best practice). Below shows how to setup the custom Editor for the BoolGameEventListener, simply specify the Listener type, and the Type of Game Event that is applicable.</p>
              <div>
                <img className='tutorial' src={require("../assets/images/SES/bool-game-event-listener-editor.png")} alt="bool game event listenre editor"/>
              </div>
              <p>Lastly, you’ll want a way to manually invoke your new Game Event from the inspector so you do not have to wait for your Producer to fire saving a lot of development time. This is a little more complicated and out of scope for this article but you can use the BoolGameEventEditor script below as a template.</p>
              <div>
                <img className='tutorial' src={require("../assets/images/SES/bool-event-editor.png")} alt="Bool game event editor"/>
              </div>
            </div>
          </Collapsible>
        </div>
      </div>
  </div>
  );
}

export default SimpleEventSystem;
