import { AppContainer } from 'react-hot-loader'
import React from 'react'
import ReactDOM from 'react-dom'
import Routing from './routes'
import configureStore, { history } from './configureStore'
import { ProSidebarProvider } from 'react-pro-sidebar'
require('./styles.css')

const store = configureStore()
const render = () => {
  ReactDOM.render(
    <AppContainer>
      <ProSidebarProvider store={store}>
        <Routing />
      </ProSidebarProvider>
    </AppContainer>,
    document.getElementById('react-root')
  )
}

render()

// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept('./routes/index', () => {
    render()
  })
}
