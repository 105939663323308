
import { Helmet } from "react-helmet";
import YouTube from 'react-youtube';

function CosmosProtector() {
  const youtubeOpts = {
      height: '390',
      width: '640',
      playerVars: {
        autoplay: 0,
      }
  };
  const transitionTime = 200;
  return (
    <div>
      <Helmet>
        <title>Cosmos Protector</title>
        <meta
            name="description"
            content="Dive into the world of Cosmos Protector and discover its mesmerizing gameplay and captivating features. Explore a universe of excitement."
        />
        <meta
          name="keywords"
          content="Cosmos Protector, Action Packed,	Bullet Hell, Shooter, Arcade, Unity, Games, Quiet, Village"
        />
      </Helmet>
      <header>
        <h2>Cosmos Protector</h2>
      </header>
      <div>
      <p>
        Introducing a rapid-paced arcade space shooter that promises an adrenaline-fueled experience! Your mission: obliterate hordes of alien ships while navigating an intense gauntlet. Brace yourself for an epic showdown as you confront a formidable boss at the journey's climax. What sets this game apart is its dynamic nature – each playthrough is a unique adventure, ensuring that no two sessions are alike. The game's total duration, including the epic boss encounter, spans approximately 6 action-packed minutes.</p>
      <p>
        For the optimal experience, we recommend immersing yourself in this thrilling adventure through native applications. The inherent capabilities of these platforms enhance the game's visuals, performance, and overall engagement, making it a must-play experience.
      </p><p>
        Get ready to embark on an exhilarating journey through the cosmos, where swift reflexes and sharp shooting skills are your ultimate allies. Dive into the heart of the action, conquer alien foes, and triumph over an epic boss battle. Your space odyssey awaits!
      </p>
      < hr />
      <div className="youtube">
        <YouTube videoId="HaScfAsKKkg" opts={youtubeOpts} />
      </div>
      < hr />
      <div>
        <p align="center">
          <iframe frameborder="0" title="Cosmos Protector" src="https://itch.io/embed/1794641?dark=true" width="552" height="167"><a href="https://xxchester.itch.io/cosmos-protector">Cosmos Protector by XXChester</a></iframe>
        </p>
      </div>
      </div>
    </div>
  );
}

export default CosmosProtector;
