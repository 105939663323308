import { Sidebar, Menu, MenuItem, useProSidebar, SubMenu  } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import {FaHome, FaUnity, FaGamepad, FaMedal, FaTelegram, FaBars, FaBroadcastTower, FaSpaceShuttle, FaLaptop, FaGripLinesVertical, FaUserSecret, FaRunning, FaFeather} from 'react-icons/fa';
import { useLocation } from 'react-router-dom'


function SideBar(props) {
    const { collapseSidebar } = useProSidebar();

    const location = useLocation().pathname;
    // console.log(location);

  return (
        <Sidebar id='sidebar'>
            <Menu renderMenuItemStyles={() => ({
                '.menu-anchor': {
                    '&:hover': {
                        backgroundColor: '#181818',
                    },
                },
            })}>
                <MenuItem onClick={() => collapseSidebar()} icon={<FaBars />}></MenuItem>
                <MenuItem routerLink={<Link to="/"/>} icon={<FaHome />} active={location === "/"}>Home</MenuItem>
                <SubMenu label="Unity Assets" icon={<FaUnity />} defaultOpen>
                    <MenuItem routerLink={<Link to="/arcadeTextInput"/>} icon={<FaGamepad />} active={location === "/arcadeTextInput"}>Arcade Text Input</MenuItem>
                    <MenuItem routerLink={<Link to="/simpleEventSystem"/>} icon={<FaBroadcastTower />} active={location === "/simpleEventSystem"}>Simple Event System</MenuItem>
                    {/* <MenuItem routerLink={<Link to="/leaderboard"/>} icon={<FaMedal />} active={location === "/leaderboard"}>Easy Leaderboard</MenuItem> */}
                </SubMenu>
                <SubMenu label="Games" icon={<FaLaptop />} defaultOpen>
                    <MenuItem routerLink={<Link to="/cosmosProtector" />} icon={<FaSpaceShuttle />}  active={location === "/cosmosProtector"}>Cosmos Protector</MenuItem> 
                    <MenuItem routerLink={<Link to="/holdTheLine" />} icon={<FaGripLinesVertical />}  active={location === "/holdTheLine"}>Hold The Line</MenuItem> 
                    <MenuItem routerLink={<Link to="/oneOnTheRun" />} icon={<FaRunning />}  active={location === "/oneOnTheRun"}>One On The Run</MenuItem> 
                    <MenuItem routerLink={<Link to="/shifters" />} icon={<FaFeather />}  active={location === "/shifters"}>Shifters</MenuItem> 
                </SubMenu>
                <MenuItem routerLink={<Link to="/contact"/>} icon={<FaTelegram />} active={location === "/contact"}>Contact Us</MenuItem>
                <MenuItem routerLink={<Link to="/privacyPolicy"/>} icon={<FaUserSecret />} active={location === "/privacyPolicy"}>Privacy Policy</MenuItem>
            </Menu>
        </Sidebar>
  );
}

export default SideBar