
import { Helmet } from "react-helmet";
import YouTube from 'react-youtube';

function Shifters() {
  const youtubeOpts = {
      height: '390',
      width: '640',
      playerVars: {
        autoplay: 0,
      }
  };
  const transitionTime = 200;
  return (
    <div>
      <Helmet>
        <title>Shifters: Exciting Puzzle-Platformer with Unique Creature Abilities | Play Now</title>
        <meta
            name="description"
            content="Experience Shifters, a thrilling puzzle-platformer where touching creatures grants you unique abilities like gliding, jumping, and running. Embark on a magical quest to find hidden crystals and master your transformations. Play now!"
        />
        <meta
          name="keywords"
          content="Shifters, puzzle-platformer, creature abilities, gliding, jumping, running, magical quest, hidden crystals, transformations, adventure game, play now"
        />
      </Helmet>
      <header>
        <h2>Shifters</h2>
      </header>
      <div>
        <p>
          Embark on a thrilling adventure with Shifters, a captivating puzzle-platformer where every encounter transforms your abilities. Immerse yourself in a world where touching a bird grants you the power to glide, all while you hunt for elusive crystals. Each creature you meet opens up new, temporary abilities, making every moment a fresh and exciting challenge. Dive into Shifters and experience the magic of shape-shifting in your quest for treasures
        </p>
      < hr />
      <div className="youtube">
        <YouTube videoId="O14GoH4ek88" opts={youtubeOpts} />
      </div>
      < hr />
      <div>
        <p align="center">
          <a href="https://play.google.com/store/apps/details?id=com.QuietVillageGames.Shifters">Google Play Store</a>
        </p>
      </div>
    </div>
  </div>
  );
}

export default Shifters;
