
function Leaderboard() {
  return (
    <div>
      <header>
        <h2>Easy Leaderboards</h2>
      </header>
      <div>
      
      </div>
    </div>
  );
}

export default Leaderboard;
