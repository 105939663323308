
import { Helmet } from "react-helmet";
import TutorialItem from './TutorialItem';

function ArcadeInput() {
  return (
    <div>
      <Helmet>
        <title>Get Your Hands on the Best Arcade Text Input</title>
        <meta
            name="description"
            content="Discover the top cross-device arcade text input for Unity"
        />
        <meta
          name="keywords"
          content="Arcade, Text, Input, Unity, Games, Quiet, Village"
        />
      </Helmet>
      <header>
        <h2>Arcade Text Input</h2>
      </header>
      <div>
        <p>Back in the day of arcade machines, most featured name entries for characters or leaderboards that used a “rolling selection of letters” which we believe is a great way to support cross platform short text entry.  With that, we are proud to introduce our Unity Asset: <a href="https://assetstore.unity.com/packages/slug/240866">Arcade Text Input</a>.</p> 
        <div>
          <img className='show-case' src={require("../assets/images/arcade-input.gif")} alt="gif showing the arcade text input in action" />
        </div>
        <p>You scroll between the letters with the Left or Right, A or D keys, gamepad Left or Right, or Left or Right on the left stick. You change the letter by scrolling up or down via the Up or Down, W or S keys, gamepad Up or Down, or Left stick Up and Down.</p>

        <p>The asset pack includes the core components needed to use the input entry and several example scenes showing off how the features all work together. This package requires the new Unity Input System, and integrates seamlessly into existing projects. </p>
      </div>
      <div>
        <hr />
        <h3>Getting Started</h3>
      </div>
      <div>
          <TutorialItem step={1} wording={"Create an action map if you haven’t already."} img={require("../assets/images/action-map.png")} />
          <TutorialItem step={2} wording={"Create a letter prefab to fit the theme of your game using a TextMeshPro Text object and the ArcadeInput’s Letter component. <b><u>Note:</u></b> The navigation type must be set to explicit for the letters as well as the objects that directly communicate with the letters; such as a submit handler button."}
              img={require("../assets/images/letter-prefab.png")} />
          <TutorialItem step={3} wording={"Setup the ArcadeInput component with your desired values in the inspector."} img={require("../assets/images/arcade-input-component.png")} />
          <TutorialItem step={4} wording={"Setup or add to an existing PlayerInput component to point navigation to the ArcadeInput components HandleInput method."}
              img={require("../assets/images/player-input-component.png")} />
          <TutorialItem step={5} wording={"Setup a submit handler. This is how you gather the full word that was created by the letters."} img={require("../assets/images/submit-handler.png")} />
          <TutorialItem step={6} wording={"Ensure you have setup the Input System UI Input Module component to use your action map."} img={require("../assets/images/event-system.png")} />
      </div>
    </div>
  );
}

export default ArcadeInput;
