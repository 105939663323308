import React from 'react'
import { Link } from 'react-router-dom';
import NewsItem from './NewsItem';

const Home = () => (
  <div>
    <div><h2/></div>
    <div>
      <p>At Quiet Village Games, our essence lies in creation. Whether it's crafting games, developing tools, or designing assets, we are driven by a passion for bringing ideas to life. Our journey revolves around the art of creation and the exploration of imaginative worlds. We take delight in inventing whimsical quirks and inventive gimmicks that add a unique touch to our creations. Moreover, we're dedicated to simplifying the lives of developers, making their tasks more manageable and efficient. Our core is rooted in creativity, innovation, and a genuine love for making things come alive.</p>
    </div>
    <div>
      <hr />
      <h2>News</h2>
    </div>
    <div>
      <NewsItem date="June 9th, 2024"
        wording="<p>Today we launched <a href='#/shifters'>Shifters</a> on the Play Store, you can play free <a href='https://play.google.com/store/apps/details?id=com.QuietVillageGames.Shifters'>here</a>.</p>"
      />
      <NewsItem date="Feb 9th, 2024"
        wording="<p>Today we launched <a href='#/oneOnTheRun'>One On The Run</a> on the Play Store, you can play free <a href='https://play.google.com/store/apps/details?id=com.QuietVillageGames.OneOnTheRun'>here</a>.</p>"
      />
      <NewsItem date="Feb 2nd, 2024"
        wording="<p>It's official! We have registered for the Google Play store and hopefully shortly, you'll see <a href='#/oneOnTheRun'>One On The Run</a> available to play on mobile.</p>"
      />
      <NewsItem date="Jan 7th, 2024"
        wording="<p>Today is the launch of One On The Run, Embark on an adrenaline-fueled escapade in an exhilarating infinite chase game that pushes your reflexes and strategic skills to the limit! Plunge into a surreal world where danger lurks at every turn, and your only objective is to outrun the relentless pursuit.</p><p>Find out more <a href='#/oneontherun'>here</a></p>"
      />
      <NewsItem date="Aug 29th, 2023"
        wording="<p>Today is the launch of Hold The Line, a  action-packed strategy game that challenges players to defend a crucial line against relentless waves of monsters. The primary objective is to safeguard your Orbs, preventing the monsters from reaching them. Players achieve this by strategically deploying a variety of units within their territory, each possessing unique abilities and offensive prowess. These units act as the player's defenders, each with a distinct role in fending off the incoming monster horde.</p><p>Find out more <a href='#/holdTheLine'>here</a></p>"
      />
      <NewsItem date="May 26th, 2023"
        wording="<p>Today is the launch of Cosmos Protector, a rapid-paced arcade space shooter that promises an adrenaline-fueled experience. Your mission: obliterate hordes of alien ships while navigating an intense gauntlet. Brace yourself for an epic showdown as you confront a formidable boss at the journey's climax. What sets this game apart is its dynamic nature – each playthrough is a unique adventure, ensuring that no two sessions are alike. The game's total duration, including the epic boss encounter, spans approximately 6 action-packed minutes.</p>"
      />
      <NewsItem date="Apr 4th, 2023"
        wording="<p>Today is the launch of our new asset <a href='#/simpleEventSystem'>Simple Event System</a>! This asset makes integrating a publisher/subscriber very easy and ships with 15 custom editors and a new Game Event References window editor. Find out more on <a href='https://assetstore.unity.com/packages/slug/247545'>The Asset Store</a> or on <a href='#/simpleEventSystem'>here</a>.</p>"
      />
      <NewsItem date="Feb 3rd, 2023"
        wording="<p>It’s official; we are now a member of the Unity Asset Store Publishers group with the launch of our <a href='#/arcadeTextInput'>Arcade Text Input</a> component! This component was built as part of our upcoming game Cosmos Protector as a means to easily input your name on the leader board without changing input devices. Find out more on <a href='https://assetstore.unity.com/packages/slug/240866'>The Asset Store</a> or on <a href='#/arcadeTextInput'>here</a>.</p>
          <p>Also, Cosmos Protector is almost ready; we are waiting on a new sound track for the boss fight than it will be released as well! Stay tuned!</p>"
      />
      <NewsItem date="Nov 20th, 2022" 
        wording="<p>Quiet Village Games is here! We did it; we finally pulled the trigger and started a company. This portal will be to document, support and announce new products or games that we are releasing. Additionally, it will be used by Unity to verify the legitimacy of our company and assets to be published on the asset store.</p>
          <p>Stay tuned to find out what’s coming!</p>" 
      />
    </div>
  </div>
)

export default Home
