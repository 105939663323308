
import { Helmet } from "react-helmet";
import YouTube from 'react-youtube';

function HoldTheLine() {
  const youtubeOpts = {
      height: '390',
      width: '640',
      playerVars: {
        autoplay: 0,
      }
  };
  const transitionTime = 200;
  return (
    <div>
      <Helmet>
        <title>Hold The Line - Defend Orbs Against Monster Waves | Tower Defense Game</title>
        <meta
            name="description"
            content=" Immerse yourself in the action-packed world of Hold The Line, a tower defense game where you strategically defend Orbs from relentless waves of monsters. Unleash god-like powers, deploy units, and master resource management to emerge victorious."
        />
        <meta
          name="keywords"
          content=" Hold The Line, tower defense game, defend Orbs, monster waves, strategy gameplay, god-like powers, unit placement, resource management, epic battles, game mechanics, official website, Games, Quiet, Village"
        />
      </Helmet>
      <header>
        <h2>Hold The Line</h2>
      </header>
      <div>
        <p>
          Hold The Line is an action-packed strategy game that challenges players to defend a crucial line against relentless waves of monsters. The primary objective is to safeguard your Orbs, preventing the monsters from reaching them. Players achieve this by strategically deploying a variety of units within their territory, each possessing unique abilities and offensive prowess. These units act as the player's defenders, each with a distinct role in fending off the incoming monster horde.
        </p>
        <p>
          As the game progresses, players accumulate gold gradually and by defeating enemies, which can then be utilized to acquire more units or unleash potent God powers. These divine abilities include healing, direct damage, and an amplify curse area-of-effect (AE) effects, allowing players to exert a god-like influence over the battlefield.        
        </p>
      < hr />
      <div className="youtube">
        <YouTube videoId="8tScZX__Bos" opts={youtubeOpts} />
      </div>
      < hr />
      <div>
        <p align="center">
          <iframe frameborder="0" title="Hold The Line" src="https://itch.io/embed/2210016?linkback=true&amp;dark=true" width="552" height="167"><a href="https://xxchester.itch.io/hold-the-line">Hold The Line by XXChester</a></iframe>
        </p>
      </div>
      </div>
    </div>
  );
}

export default HoldTheLine;
