
function Contact() {
  return (
    <div>
      <header>
        <h2>Contact Us</h2>
      </header>
      <div>
        <p>We welcome the opportunity to connect with you! If you have any questions, require support, or wish to explore any other inquiries, please don't hesitate to reach out to us at <a href="mailto:QuietVillageGamesHelp@gmail.com">QuietVillageGamesHelp@gmail.com</a>.</p>
        <p>For real-time interaction and engaging discussions, we also invite you to join our community on  <a href="https://discord.gg/Qsfwrm3Ypm">Discord.</a>. Your thoughts and feedback are invaluable to us, and we look forward to fostering a meaningful connection with you.</p>
      </div>
    </div>
  );
}

export default Contact;
